<template>
  <div class="benefits rounded dashboard-usertype profile-info">
    <div class="border-bottom d-flex justify-content-between align-items-center pb-2">
      <div>
        <h4 class="section-title">User Type</h4>
      </div>
    </div>

    <div class="type-wrapper">
      <div class="item" :class="{ 'border-primary bg-whiteBlue': sellerType === '1' }">
        <div>
          <b-form-checkbox v-model="sellerType" value="1"
            >Professional Seller</b-form-checkbox
          >
          <p>Sell more than 6 vehicles per month on Pash</p>
        </div>
      </div>
      <div class="item" :class="{ 'border-primary bg-whiteBlue': sellerType === '2' }">
        <div>
          <b-form-checkbox v-model="sellerType" value="2"
            >Indivdual Seller</b-form-checkbox
          >
          <p>Sell less than 6 vehicles per months on Pash</p>
        </div>
      </div>
      <div class="item" :class="{ 'border-primary bg-whiteBlue': buyerType }">
        <div>
          <b-form-checkbox v-model="buyerType" value="3">Buyer</b-form-checkbox>
          <p>Buy cars internationally with Pash</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buyerType: null,
      sellerType: null,
    };
  },
  async updateUser() {
    //Changes the user info in the DB

    let countryCode;
    this.$store.getters.countries.forEach((element) => {
      if (this.country === element.Name) countryCode = element.Code;
    });
    this.$store.dispatch("updateUser", {
      ID: this.$store.state.user.user.UserId,
      FirstName: this.firstName,
      LastName: this.lastName,
      LanguageCode: this.$i18n.locale,
      CountryCode: countryCode,
      Mobile: this.mobile,
    });
    this.$router.push({ name: "PrimaryContact" });
  },
};
</script>

<style lang="scss">
.dashboard-usertype {
  .section-title {
    font-weight: 600;
    font-size: 20px;
    font-family: "Inter", sans-serif !important;
    color: #111111;
    margin-bottom: 0;
  }
  .type-wrapper {
    display: flex;
    column-gap: 24px;
    row-gap: 24px;
    margin-top: 24px;
    flex-wrap: wrap;
    .item {
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      width: calc((100% / 2) - 12px);
      padding: 24px;
    }
    label {
      font-size: 16px;
      font-weight: 500;
      color: #111111;
      font-family: "Inter", sans-serif !important;
      padding-left: 8px;
    }
    p {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 14px;
      padding-left: 32px;
    }
    .custom-control {
      min-height: auto;
    }
  }
}
</style>
